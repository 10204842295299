/* eslint-disable @typescript-eslint/no-explicit-any */
export interface InputFormPayload {
  pv_size_dc_kw_qt: number;
  avoided_cost_per_kwh_qt: number;
  disc_to_pre_system_utility_bill_pcnt_inp: number;
  kwh_per_kw_qt: number;
  epc_total_per_watt_inp_qt: number;
  sr_finl_dev_fee_per_watt_inp: number;
  state_incentive_yr1_nom_qt: number;
  site_lease_included: 'Yes' | 'No';
  site_lease_annual_nom: number;
  site_lease_term: number;
  site_lease_escal: string; // Assuming escalatorOptions is a string array
  financing_type: 'PPA' | 'Lease';
  ppa_term_yrs_num?: '15' | '20' | '25';
  ppa_escal_orig_term?: string; // Assuming escalatorOptions is a string array
  lease_term_yrs_num?: '15' | '20' | '25';
  lease_escal_orig_term?: string; // Assuming escalatorOptions is a string array
  custom_useful_life_term: number;
  itc_adder_domestic_qt: 'Yes' | 'No';
  itc_adder_energy_com_qt: 'Yes' | 'No';
  itc_adder_low_inc_qt: 'Yes' | 'No';
  custom_opp_maintain_cost_applies: 'Yes' | 'No';
  custom_opp_maintain_cost_yr1_per_kw: number;
  custom_opp_maintain_cost_yr6_per_kw: number;
  custom_opp_maintain_cost_escal: string; // Assuming escalatorOptions is a string array
  offtaker_holds_rec_rights: 'Yes' | 'No';
  ppa_prod_guar_included?: 'Yes' | 'No';
  itc_ineligible_nom_qt: number;
  rec_type: 'Adjustable Block Program' | 'Successor Solar Incentive (SuSI) Program' | 'VA REC' | 'Pennsylvania Tier I RECs';
  rec_group: 'Group A: Ameren' | 'Group B: ComEd' | 'PJM' | 'Not Applicable';
  public_school: 'Yes' | 'No';
  public_entity: 'Yes' | 'No';
  ac_system_size_multiplier: number;
  ac_system_size_estimated: number;
  utility_name: string;
  epc_total_per_watt_sd: number;
  epc_total_per_watt_sd_dep: number;
  kwh_per_kw_sd: number;
  kwh_per_kw_sd_dep: number;
  pv_size_dc_kw_sd: number;
  state_incentive_yr1_nom_sd: number;
  ac_system_size_kw: number;
  epc_total_per_watt_inp_sd: number;
}

export const initializeFormPayload = (
  quoteData: any,
  systemDesignData: any,
  opportunityData: any
): InputFormPayload => {
  const result: Partial<InputFormPayload> = {};
  // Helper function to set a value if it exists
  function setValue(key: keyof InputFormPayload, value: any) {
    if (value) {
      result[key] = value;
    }
  };

  // Populate from quoteData
  if (quoteData) {
    Object.keys(quoteData).forEach((key) => {
      const formKey = key.replace('__c', '') as keyof InputFormPayload;
      setValue(formKey, quoteData[key]);
    });
  }

  // Populate from systemDesignData
  if (systemDesignData) {
    Object.keys(systemDesignData).forEach((key) => {
      const formKey = key.replace('__c', '') as keyof InputFormPayload;
      // if ends with _sd_dep then set the value to the value of the key without the _dep
      if (formKey.endsWith('_sd_dep')) {
        const formKeyWithoutDep = formKey.replace('_sd_dep', '_sd') as keyof InputFormPayload;
        setValue(formKeyWithoutDep, systemDesignData[key]);
      } else if (formKey.endsWith('_sd')) {
        setValue(formKey, systemDesignData[key]);
      }
    });
  }

  // Populate from opportunityData
  if (opportunityData) {
    Object.keys(opportunityData).forEach((key) => {
      const formKey = key.replace('__c', '') as keyof InputFormPayload;
      setValue(formKey, opportunityData[key]);
    });
  }

  // Set default values for fields that are still undefined
  setValue('ac_system_size_multiplier', result.ac_system_size_multiplier || 1.25);
  setValue('ac_system_size_estimated', result.ac_system_size_estimated || 0);
  setValue('ac_system_size_kw', systemDesignData.ac_system_size_kw__c || 0);

  // Ensure all fields have at least an empty string value
  (Object.keys(result) as Array<keyof InputFormPayload>).forEach((key) => {
    if (result[key] === undefined) {
      result[key] = undefined;
    }
  });
  return result as InputFormPayload;
}