import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionContext, Card, Col, Dropdown, DropdownButton, Row, InputGroup, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Accordion from 'react-bootstrap/Accordion';
import Pagination from 'react-bootstrap/Pagination';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { toast } from 'react-toastify';
import Select from 'react-select'
import { Button, CircularProgress } from '@mui/material';
import useOpportunitySearchStore from '../../hooks/useOpportunitySearchStore';
import useOpportunityQuotes from '../../api/useOpportunityQuotes';
import LoadingModal from '../../components/CustomModal/LoadingModal';
import '../../components/Footer/Footer';
import '../../components/NavBar/NavBar';

import { useNavigate } from 'react-router-dom';
import useGetOpportunities from '../../api/useGetOpportunities';
import useDebounce from '../../hooks/useDebounce';
import CustomModal from '../../components/CustomModal/CustomModal';
import UploadModal from '../../components/CustomModal/UploadModal';
import { columns } from '../../constants';
import './Home.css';

const perPage = 50;

function Home() {
  const [modalShow, setModalShow] = useState({});
  const navigate = useNavigate();
  const [uploadedFileName, setUploadedFileName] = useState(undefined);
  const [fileData, setFileData] = useState(undefined);
  const [selectedOpp, setSelectedOpp] = useState(null);

  // Query
  const storeSearchTerm = useOpportunitySearchStore((state) => state.searchTerm);
  const setStoreSearchTerm = useOpportunitySearchStore((state) => state.setSearchTerm);
  const openOpportunityId = useOpportunitySearchStore((state) => state.openOpportunityId);
  const setOpenOpportunityId = useOpportunitySearchStore((state) => state.setOpenOpportunityId);
  const setOpenOpportunityTab = useOpportunitySearchStore((state) => state.setOpenOpportunityTab);
  const openOpportunityTab = useOpportunitySearchStore((state) => state.openOpportunityTab);
  const useDebouncedStoreSearchTerm = useDebounce(storeSearchTerm, 500);
  // Pagination
  const [pageNumber,setPageNumber] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const useDebouncedSearchTerm = useDebounce(searchTerm, 500);
  const [stageFilters, setStageFilters] = useState([])
  const [sort, setSort] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const { data: opportunitiesData, isLoading: loadingOpportunities, isFetching: fetchingOpportunities, error: opportunitiesError } = useGetOpportunities({
    perPage,
    pageNumber,
    searchTerm: useDebouncedSearchTerm || useDebouncedStoreSearchTerm,
    stageFilters,
    sort,
    statusFilter: statusFilter || openOpportunityTab
  });

  const { data: opportunityQuotesData, isLoading: loadingOpportunityQuotes } = useOpportunityQuotes(openOpportunityId);

  if (opportunitiesData?.opportunities?.count === 0) {
    return <p>No opportunities found</p>
  }

  if (opportunitiesData === undefined && loadingOpportunities) {
    return <CircularProgress color='primary' />
  }

  if (opportunitiesError) {
    navigate('/login');
  }

  const sortOptions = {
    'Name': 'Name',
    'StageName': 'Stage',
    'Project_ID__c': 'Project ID'
  }

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber)
  }

  const doSearch = (e) => {
    setPageNumber(1)
    setSearchTerm(e.target.value);
    setStoreSearchTerm(e.target.value);
  }

  const handleSearch = (e) => {
    doSearch(e);
  }

  const handleFilterSelect = (e) => {

    let filters = [];
    e.map(x => {
      filters.push(x.value);
    })
    setPageNumber(1)
    setStageFilters(filters);
  }

  const handleSortBySelect = (e) => {
    setPageNumber(1)
    setSort(e)
  }

  const handleStatusChange = (value) => {
    setPageNumber(1)
    setStatusFilter(value)
    setOpenOpportunityTab(value)
  }

  function renderLoadingModal() {
    return (
      <>
        <LoadingModal
          show={loadingOpportunityQuotes}
          onHide={() => setModalShow(false)}
        />
      </>
    );
  }

  function CustomToggle({ children, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);
    const isCurrentEventKey = activeEventKey === eventKey;
    const decoratedOnClick = useAccordionButton(eventKey);
    const handleOpen = () => {
      setOpenOpportunityId(eventKey);
      decoratedOnClick();
    }
    const opportunity = opportunitiesData.opportunities.filter(x => x.applicationId === eventKey);
    if (opportunity && opportunity.length) {
      const selectedOpp = opportunity[0];
      return (
        <Row>
          <Col sm={1}>
            <Button onClick={handleOpen}
              variant='link'
              style={{ background: 'white' }}>
              <Image src={isCurrentEventKey ? './chevron-down-open.png' : './chevron-down-inactive.png'}></Image>
              {children}
            </Button>
          </Col>
          <Col sm={2} style={{ marginTop: '8px' }}>
            <p>{selectedOpp.projectId}</p>
          </Col>
          <Col sm={3} style={{ marginTop: '8px' }}>
            <p style={{ fontWeight: 'bold' }}>{selectedOpp.businessName}</p>
          </Col>
          <Col sm={3} style={{ textAlign: 'center', paddingRight: '40px', marginTop: '5px' }}>
            <Button variant='contained' sx={{color: 'white'}} disabled>{selectedOpp.StageName}</Button>
          </Col>
          <Col sm={3} style={{ textAlign: 'right', paddingRight: '40px', marginTop: '5px' }}>
            <Button disabled sx={{color: 'white'}} style={{
              border: 'none',
              backgroundColor: (selectedOpp.status === 'Active' ? 'rgba(68, 118, 173, 0.1)' :
                (selectedOpp.status === 'Lost' ? 'rgba(235, 87, 87, 0.1)' : 'rgba(1, 148, 68, 0.1)')),
              color: (selectedOpp.status === 'Active' ? '#4476AD' : (selectedOpp.status === 'Lost' ? '#EB5757' : '#FF7E0B'))
            }}>
              {selectedOpp.status}
            </Button>
          </Col>
        </Row>
      );
    }
  }

  CustomToggle.propTypes = {
    children: PropTypes.node,
    eventKey: PropTypes.string,
  };

  const handleViewClicked = (e) => {
    let url = '/calculator?id=' + e.Id + '&opportunityId=' +
      e.OpportunityId + '&businessName=' + encodeURIComponent(e.Opportunity.Name) + '&projectId=' +
      e.Opportunity.Project_ID__c + '&action=view' + '&quoteId=' + e.QuoteNumber;
    if (e.system_design__c !== null) {
      url += '&systemDesign=' + e.system_design__c ;
    }
    if (e.calculator__c !== null && e.is_manual__c === false) {
      navigate(url + '&version=' + e.calculator__c);
    } else {
      navigate(url);
    }
  }

  const handleCloneClicked = (e) => {
    let url = '/calculator?id=' + e.Id + '&opportunityId=' +
    e.OpportunityId + '&businessName=' + encodeURIComponent(e.Opportunity.Name) + '&projectId=' +
    e.Opportunity.Project_ID__c + '&action=clone';
    if (e.system_design__c !== null) {
      url += '&systemDesign=' + e.system_design__c;
    }

    navigate(url);
  }

  function getActionsByQuoteStatus(row) {
    let options = [];
    if (['In Review', 'Approved', 'Presented', 'Accepted', 'Denied'].includes(row.Status)) {
      // clone yes, edit no, delete no

      options.push(
        <>
          <Dropdown.Item onClick={() => handleCloneClicked(row)}>
            <Image src="../clone.png" style={{ paddingRight: '10px' }} />
            Clone Quote
          </Dropdown.Item>
        </>
      );
    } else if (['Rejected'].includes(row.Status)) {
      // edit no, clone yes, delete yes
      options.push(
        <>
          <Dropdown.Item onClick={() => handleCloneClicked(row)}><Image src="../clone.png" style={{ paddingRight: '10px' }} />Clone Quote</Dropdown.Item>
          {/* <Dropdown.Item onClick={() => handleDeleteClicked(row)}><Image src="../delete.png" style={{ paddingRight: '10px' }} />
            Delete Quote</Dropdown.Item> */}
        </>
      )
    } else {
      // edit yes, clone yes, delete yes
      options.push(
        <>
          {/* <Dropdown.Item onClick={() => handleEditClicked(row)}><Image src="../edit.png" style={{ paddingRight: '10px' }} />Edit Quote</Dropdown.Item> */}
          <Dropdown.Item onClick={() => handleCloneClicked(row)}><Image src="../clone.png" style={{ paddingRight: '10px' }} />Clone Quote</Dropdown.Item>
          {/* <Dropdown.Item onClick={() => handleDeleteClicked(row)}><Image src="../delete.png" style={{ paddingRight: '10px' }} />
            Delete Quote</Dropdown.Item> */}
        </>
      );
    }
    return (<Dropdown variant='link' key={`dropdown-${row.databasePkey}`}>
      <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: "white", border: 0 }}>
        <Image src='../vertical-elipses.png' />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleViewClicked(row)}><Image src="../view.png" style={{ paddingRight: '10px' }} />View Quote</Dropdown.Item>
        {/* <Dropdown.Item onClick={() => handleSubmitToEng(row.databasePkey)}><Image src="../submit-to-eng.png" style={{ paddingRight: '10px' }} />
              Submit to Engineering</Dropdown.Item> */}
        {options}
      </Dropdown.Menu>
    </Dropdown>);
  }

  const handleUploadSQ = (opportunityId) => {

    setSelectedOpp(opportunityId);

    const input = document.querySelector(`input[id="${opportunityId}"]`);
    if (input) {
      input.click();
    }
  }

  const handleDisplayFileDetails = (e) => {
    setUploadedFileName(e.target.files[0].name)
    setFileData(e.target.files[0])
  };

  const handleUploadConfirmed = () => {

    const data = new FormData();
    if (uploadedFileName) {
      data.append('file', fileData);
      data.append('filename', uploadedFileName);

      fetch('/api/export-xl-to-sf?id=' + selectedOpp, {
        method: 'POST',
        body: data,
      }).then((response) => {
        response.json().then((body) => {
          if (body.error) {
            console.log(body.error);
            toast.warning('Failed to export got error, retry. Error is ' + body.error, {
              position: toast.POSITION.TOP_RIGHT
            });
            setUploadedFileName(undefined);
            setFileData(null);
          } else {
            for (let i = 0; i < body.length; i++) {
              if (body[i].errors && body[i].errors.length === 0) {
                toast.success('Successfully Exported with id for ' +
                  (i === 1 ? 'Quote' : 'System Design') + ' ' + body[i].id, {
                  position: toast.POSITION.TOP_RIGHT
                });
                toast.success('Please refresh to view the new quote');
                setUploadedFileName(undefined);
                setFileData(null);
                // TODO: refresh page post creation and upload for SQ
              } else {
                toast.warning('Failed to export, retry. Error is: ' + body[i].errors, {
                  position: toast.POSITION.TOP_RIGHT
                });
                setUploadedFileName(undefined);
                setFileData(null);
              }
            }
          }
        }).catch(error => {
          toast.warning('Failed to export, retry', {
            position: toast.POSITION.TOP_RIGHT
          });
          setUploadedFileName(undefined);
          setFileData(null);
          console.log(error);
        });
      });
    }
  }

  const renderUploadModal = () => {
    if (uploadedFileName) {
        return (
          <>
            <UploadModal
              show={uploadedFileName}
              onHide={() => {
                setUploadedFileName(undefined);
                setFileData(null);
              }}
              onUpload={() => handleUploadConfirmed()}
              fileName={uploadedFileName}
            />
          </>
        );
    }
  }

  function renderQuotes(opp) {

    let result = [];
    const eventKey = opp.applicationId;
    result.push(
      <Button sx={{color: 'white'}} variant='contained' onClick={() =>
        setModalShow({ vis: true, eventKey: opp.projectId })}>
        Create New Sales Quote
      </Button>
    );

    result.push(

      <input
        id={eventKey}
        className="d-none"
        type="file"
        onChange={(e) => handleDisplayFileDetails(e)}
      />

    );

    result.push(
      <Button variant='contained' sx={{color: 'white'}} style={{ marginLeft: '10px' }} onClick={() => handleUploadSQ(eventKey)}>
        Upload Custom Sales Quote
      </Button>
    );
    const renderLst = opportunityQuotesData?.quotes;
    if (renderLst && renderLst.length) {
      const salesQuotes = renderLst;
      if (salesQuotes && salesQuotes.length) {
        const sqButton = {
          dataField: "databasePkey",
          text: "Actions",
          formatter: (cellContent, row) => {
            return getActionsByQuoteStatus(row);
          }
        }
        const columnsWithButton = columns.concat(sqButton);
        result.push(
          <BootstrapTable
            keyField="quoteId"
            className='custom-quotes-table'
            data={salesQuotes}
            bordered={false}
            columns={columnsWithButton} />
        )
      }
    }
    return result;
  }

  function renderOpportunities(opportunities, journey) {
    let result = [];
    if (opportunities && opportunities.length) {
      for (let i = 0; i < opportunities.length; i++) {
        result.push(
          <Card>
            <Card.Header>
              <CustomToggle eventKey={opportunities[i].applicationId}>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={opportunities[i].applicationId}>
              <Card.Body className='quotes-accordian'>
                {opportunityQuotesData?.quotes !== undefined ? renderQuotes(opportunities[i]) :
                  'No quotes found'}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      }
    } else {
      return (
        <p style={{ height: '200px' }}>There are 0 {journey} opportunities to display</p>
      )
    }
    return result;
  }

  function renderCreateSQ() {

    if (modalShow) {
      let filteredOpp = opportunitiesData.opportunities.filter(x => x.projectId === modalShow.eventKey);
      let oppId = undefined;
      let businessName = '';
      let projectState = '';
      if (filteredOpp && filteredOpp.length) {
        businessName = filteredOpp[0].businessName;
        projectState = filteredOpp[0].projectState;
        oppId = filteredOpp[0].applicationId;
      }

      return (
        <>
          <CustomModal
            show={modalShow.vis}
            onHide={() => setModalShow({ vis: false, eventKey: null })}
            businessName={businessName}
            applicationId={modalShow.eventKey}
            opportunityId={oppId}
            projectState={projectState}
          />
        </>
      );
    }
  }

  const paginationSummary = () => {
      if (opportunitiesData.total === 0) {
        return `${opportunitiesData.total} records`
      }
      const start = Math.max((pageNumber - 1) * perPage + 1, 1);
      const end = Math.min(start + perPage - 1, opportunitiesData.total);
      return `${start}-${end} of ${opportunitiesData.total} records`;
  }

  const getPagination = () => {
    const pages = [];
    const pagesCount = Math.ceil(opportunitiesData.total / perPage);
    for (let number = 1; number <= pagesCount; number++) {
      pages.push(
        <Pagination.Item onClick={() => handlePageChange(number)} key={number} active={number === pageNumber}>
          {number}
        </Pagination.Item>,
      );
    }

    const atEnd = pageNumber === pagesCount
    const atStart = pageNumber === 1

    if (pagesCount <= 1) {
      return null
    }

    return (

      <Pagination className="custom-pagination">
        <Pagination.First
          disabled={atStart}
          onClick={() => handlePageChange(1)}
          />
        <Pagination.Prev
          onClick={() => handlePageChange(pageNumber - 1)}
          disabled={atStart}
          />
        {pages}
        <Pagination.Next
          disabled={atEnd}
          onClick={() => handlePageChange(pageNumber + 1)}
          />
        <Pagination.Last
          onClick={() => handlePageChange(pagesCount)}
          disabled={atEnd}
          />
      </Pagination>
    )
  }

  const renderSortOptions = () => {

    const rendered = []
    for (const [key, value] of Object.entries(sortOptions)) {
      rendered.push(<Dropdown.Item eventKey={key} onClick={() => handleSortBySelect(key)}>{value}</Dropdown.Item>)
    }

    return rendered
  }

  function renderTabs() {
    const categories = ['Active', 'Won', 'Lost'];
    let result = [];

    for (let i = 0; i < categories.length; i++) {
      result.push(
        <Tab.Pane eventKey={categories[i]}>
          <Row className='number-section'>
            <Col sm={3}>
              {
                <div className='view-all-btn' >
                  <InputGroup>
                    <Button sx={{color: 'white'}} variant="contained" id="button-addon1" disabled>
                      Search
                    </Button>
                    <Form.Control
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      onChange={e => handleSearch(e)}
                      value={searchTerm || storeSearchTerm}
                    />
                  </InputGroup>
                </div>
              }
            </Col>
            <Col sm={3}>
              {
                <div className='view-all-btn' >
                  <Select options={opportunitiesData.renderStageNames} closeMenuOnSelect={false} isMulti onChange={(e) => handleFilterSelect(e)}>
                    Filter by Status</Select>
                </div>
              }
            </Col>
            <Col>
              {
                <div className='view-all-btn' >
                  <DropdownButton
                    id="dropdown-basic-button"
                    variant='outline-success'
                    title={sort in sortOptions ? sortOptions[sort] : "Sort"}
                    >
                    {renderSortOptions()}
                  </DropdownButton>
                </div>
              }
            </Col>
            <Col sm={1}>
              <p>{paginationSummary()}</p>
            </Col>
          </Row>
          {
            fetchingOpportunities ? <p>Searching...</p> : null
          }
          <Accordion defaultActiveKey={[openOpportunityId]} style={{'minHeight': '300px'}}>
            {renderOpportunities(opportunitiesData.opportunities, categories[i])}
          </Accordion>
        </Tab.Pane>
      );
    }
    return result;
  }

  return (
    <div style={{ overflow: 'auto ' }}>
      <Container>
        {renderLoadingModal()}
        <h2 className='page-heading'>Opportunities</h2>
        <Tab.Container defaultActiveKey={openOpportunityTab || "Active"}>
          <Row>
            <Nav className='custom-tabs'>
              <Nav.Item>
                <Nav.Link onClick={() => handleStatusChange("Active")} eventKey="Active">Active</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => handleStatusChange("Won")} eventKey="Won">Won</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => handleStatusChange("Lost")} eventKey="Lost">Lost</Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <Row sm={9}>
            <Tab.Content>
              {opportunitiesData.opportunities && renderTabs()}
              {renderCreateSQ()}
              {renderUploadModal()}
            </Tab.Content>
          </Row>
        </Tab.Container>
        <div style={{display: "flex", justifyContent: "center"}}>
          {getPagination()}
        </div>
      </Container>
    </div>
  );
}

export default Home;
